.styled2 {
    text-decoration: none;
    color: #FFFFFF;
}

.styled2:hover {
    text-decoration: none;
}

.styled2:active {
    text-decoration: underline;
    color: #FFFFFF;
}

.corners {
    position: absolute;
    background-size: cover;
    width: 2vw;
    height: 2vw
}

