
.navbar-pfp {
    width: 60%;
    height: auto;
    border-radius: 50%;
}

@media (min-width:600px) {
    .navbar-pfp {
        width: 20%;
    }
};