.inner {
    width: 100%;
    height: 100%;
    border-top: 130px solid rgba(255, 255, 255, 0.5);
    border-bottom: 130px solid rgba(255, 255, 255, 0.5);
    border-left:  100px solid rgba(255, 255, 255, 0.5);
    border-right:  100px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
  }

.styled {
    text-decoration: none;
    color: #000000;
}

.styled:hover {
    text-decoration: underline;
}
.styled:active {
    text-decoration: underline;
    color: #de3434;
}

/* Mobile margins*/
.inner {
    width: 98%;
}
.margin-tpreview {
    margin-left: 2%;
}

/* Tablet margins*/
@media (min-width:600px) {
    .inner {
        width: 90%;
    }
    .margin-tpreview {
        margin-left: 5%;
    }
}

/* Desktop margins*/
@media (min-width:900px) {
    .inner {
        width: 80%;
    }
    .margin-tpreview {
        margin-left: 7%;
    }
}

/* Monitor margins*/
@media (min-width:1700px) {
    .inner {
        width: 70%;
    }
    .margin-tpreview {
        margin-left: 10%;
    }
}