.wrapper{
    position: relative;
    width: 95vmin;
    background-color: #ffffff;
    padding: 0px 40px 20px 40px;
    border-radius: 10px;
}
.container{
    position: relative;
    width: 100%;
    height: 100px;
}
input[type="range"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
}
.slider-track{
    width: 100%;
    height: 5px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 5px;
}
input[type="range"]::-webkit-slider-runnable-track{
    -webkit-appearance: none;
    height: 5px;
}
input[type="range"]::-moz-range-track{
    -moz-appearance: none;
    height: 5px;
}
input[type="range"]::-ms-track{
    appearance: none;
    height: 5px;
}
input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 1.7em;
    width: 1.7em;
    background-color: #3264fe;
    cursor: pointer;
    margin-top: -9px;
    pointer-events: auto;
    border-radius: 50%;
}
input[type="range"]::-moz-range-thumb{
    -webkit-appearance: none;
    height: 1.7em;
    width: 1.7em;
    cursor: pointer;
    border-radius: 50%;
    background-color: #3264fe;
    pointer-events: auto;
}
input[type="range"]::-ms-thumb{
    appearance: none;
    height: 1.7em;
    width: 1.7em;
    cursor: pointer;
    border-radius: 50%;
    background-color: #3264fe;
    pointer-events: auto;
}
input[type="range"]:active::-webkit-slider-thumb{
    background-color: #ffffff;
    border: 3px solid #3264fe;
}
.values{
    background-color: #3264fe;
    width: 32%;
    position: relative;
    margin: auto;
    padding: 10px 0;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    color: #ffffff;
}
.values:before{
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-top: 15px solid #3264fe;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    margin: auto;
    bottom: -14px;
    left: 0;
    right: 0;
}

.styled {
    text-decoration: none;
    color: #000000;
}

.styled:hover {
    text-decoration: underline;
    
}
.styled:active {
    text-decoration: underline;
    color: #fe2f2f;
}

.styled2 {
    text-decoration: none;
    color: #FFFFFF;
}

.styled2:hover {
    text-decoration: none;
}

.styled2:active {
    text-decoration: underline;
    color: #FFFFFF;
}

.mcmenu {
    -webkit-animation: fadein .3s linear forwards;
    animation: fadein .3s linear forwards;
    padding: 10px;
}
.mcmenu-fadeout{
    display: flex;
    align-items: center;
    padding: 10px;
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}
    
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
    
@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}




/* Mobile margins*/
.margin {
    margin-left: 1%;
    margin-right: 1%;
}
.margin2 {
    margin-left: 3%;
    margin-right: 3%;
}
.margin3 {
    margin-left: 2%;
}
.title-header {
    font-size: 8vw;
}

/* Tablet margins*/
@media (min-width:600px) {
    .margin {
        margin-left: 5%;
        margin-right: 5%;
    }
    .margin2 {
        margin-left: 8%;
        margin-right: 8%;
    }
    .margin3 {
        margin-left: 5%;
    }
    .title-header {
        font-size: 6vw;
    }
}

/* Desktop margins*/
@media (min-width:900px) {
    .margin {
        margin-left: 10%;
        margin-right: 10%;
    }
    .margin2 {
        margin-left: 11%;
        margin-right: 11%;
    }
    .margin3 {
        margin-left: 11%;
    }
    .title-header {
        font-size: 3.7vw;
    }
}

/* Monitor margins*/
@media (min-width:1700px) {
    .margin {
        margin-left: 15%;
        margin-right: 15%;
    }
    .margin2 {
        margin-left: 20%;
        margin-right: 20%;
    }
    .margin3 {
        margin-left: 17%;
    }
    .title-header {
        font-size: 3.3vw;
    }
}

