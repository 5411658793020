
/* Mobile margins*/
.b1 {
    background-color: #B4B8AB;
    color: rgb(255, 255, 255);
    font-family: trebuchet ms;
    display: inline;
    border-radius: 11px;
}
  
.panel-button {
    width: 35vw;
    height: 20vw;
    font-size: 5vw;
}


/* Tablet margins*/
@media (min-width:600px) {
    .panel-button {
        width: 30vw;
        height: 18vw;
        font-size: 4vw;
    }
}

/* Desktop margins*/
@media (min-width:900px) {
    .panel-button {
        width: 17vw;
        height: 10vw;
        font-size: 2.5vw;
    }
}

/* Monitor margins*/
@media (min-width:1700px) {
    .panel-button {
        width: 17vw;
        height: 10.2vw;
        font-size: 2vw;
    }
}

