.expand {
    padding-left: 0;
    max-height: 10000px;
    transition: max-height 0.7s ease-out;
}

.collapse {
    padding-left: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease-out;
}